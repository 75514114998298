import * as fromAuth from '../actions/auth.actions';
import { createReducer, on } from '@ngrx/store';

export interface AuthState {
  isLoggedIn: boolean;
  loading: boolean;
  loaded: boolean;
}

export const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  loaded: false
};

export const authReducer = createReducer(
  initialState,
  on(fromAuth.login, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(fromAuth.loginSuccess, (state, action) => ({
    isLoggedIn: true,
    loading: false,
    loaded: true
  })),
  on(fromAuth.unauthorized, fromAuth.logout, (state, action) => initialState)
);

export const getIsLoggedIn = (state: AuthState) => state.isLoggedIn;
export const getLoading = (state: AuthState) => state.loading;
export const getLoaded = (state: AuthState) => state.loaded;
