export const environment = {
  production: true,
  // todo: fix this for DEMO env
  clientID: 'xhqvT5jGkz6faq4sdSA6O5V8LJ7qFmIz',
  domain: 'demo-auth.cargosignal.dev',
  redirect_uri: 'https://demo.cargosignal.dev/callback',
  authScope: 'openid profile email',
  audience: 'https://app.cargosignal.com',
  appUrlPrefix: 'https://demo.cargosignal.dev',
  whitelistedDomains: ['localhost:4200', 'cargosignal.dev', 'demo.cargosignal.dev']
};
