import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DemoShipmentService {
  constructor(private http: HttpClient) {}

  postOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json').set('Cache-Control', 'no-cache')
  };

  /**
   * Creates a Demo Company Shipment
   * @param companyId the company id
   * @param customerName the name of the customer
   * @param email the email of the user creating the demo shipment
   */
  createDemoShipments(companyId: string, customerName: string, email: string) {
    return this.http.post<void>(`/v3/shipments/demo?cmd=createShipments`, { companyId, customerName, email });
  }
}
