import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';
import { Observable } from 'rxjs';
import { Company } from '@cargo-signal/shared';

@Component({
  selector: 'app-create-company-success',
  templateUrl: './create-company-success.component.html',
  styleUrls: ['./create-company-success.component.scss']
})
export class CreateCompanySuccessComponent implements OnInit {
  company$: Observable<Company>;
  constructor(private store$: Store<fromStore.State>) {}

  ngOnInit() {
    this.company$ = this.store$.pipe(select(fromStore.getCompany));
  }

  handleResetState() {
    this.store$.dispatch(fromStore.resetCreateDemoShipmentsFlag());
  }
}
