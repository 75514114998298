import { Component } from '@angular/core';
import * as fromStore from '@app-root/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-error',
  templateUrl: './error.page.html',
  styleUrls: ['./error.page.scss']
})
export class ErrorPage {
  constructor(private store$: Store<fromStore.State>) {}

  tryAgain() {
    this.store$.dispatch(fromStore.logout());
  }
}
