import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as pagesActions from '../actions/pages.actions';
import * as routerActions from '../actions/router.actions';
import * as selectors from '../selectors';
import { AccountService, Company, CompanyType, User } from '@cargo-signal/shared';
import { of } from 'rxjs';
import { DemoShipmentService } from '@app-root/pages/services/demo-shipment.service';

@Injectable()
export class PagesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private accountService: AccountService,
    private demoShipmentService: DemoShipmentService
  ) {}

  /**
   * Post to account services to create a company
   */
  createCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pagesActions.createCompany),
      switchMap(action => {
        const companyObject: Company = {
          companyId: null,
          name: action.companyName,
          type: CompanyType.CUSTOMER,
          active: true,
          specialInstructions: '',
          otherInstructions: ''
        };
        return this.accountService.saveCompany(companyObject).pipe(
          map(responseCompany => {
            return pagesActions.createDemoShipments({ company: responseCompany });
          }),
          catchError(error => {
            return of(pagesActions.createCompanyFail());
          })
        );
      })
    );
  });

  createDemoShipments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pagesActions.createDemoShipments),
      withLatestFrom(this.store$.pipe(select(selectors.selectEmail))),
      switchMap(([company, email]) => {
        return this.demoShipmentService
          .createDemoShipments(company.company.companyId, company.company.name, email)
          .pipe(
            map(responseCompany => {
              return pagesActions.createDemoShipmentsSuccess();
            }),
            catchError(error => {
              return of(pagesActions.createDemoShipmentsFail());
            })
          );
      })
    );
  });

  createDemoShipmentsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(pagesActions.createDemoShipmentsSuccess),
      map(company => {
        return routerActions.go({ path: ['create-company-success'] });
      })
    );
  });
}
