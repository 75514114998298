import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@cargo-signal/shared';
import * as fromStore from '@app-root/store';
import * as actions from '@app-root/store/actions';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private store$: Store<fromStore.State>, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const path = state.url?.split('state=')[1];
    const error = state.url?.indexOf('#error=unauthorized') !== -1;
    if (path?.startsWith('/shipments') && !error) {
      const token = state.url.substring(state.url.indexOf('=') + 1, state.url.indexOf('&'));
      this.store$.dispatch(actions.login());
      return of(true);
    }

    return this.checkAuthentication(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }

  checkAuthentication(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkStoreAuthentication().pipe(
      map(storeAuth => {
        const apiAuth = this.checkApiAuthentication();
        return { storeAuth, apiAuth };
      }),
      map(({ storeAuth, apiAuth }) => {
        if (!storeAuth && !apiAuth) {
          if (route.queryParams.code && route.queryParams.state) {
            this.store$.dispatch(actions.handleCallback({ url: state.url }));
          } else {
            this.store$.dispatch(actions.go({ path: ['/login'] }));
          }
          return false;
        }
        if (!storeAuth) {
          this.store$.dispatch(actions.loginSuccess());
        }
        return true;
      })
    );
  }

  checkStoreAuthentication(): Observable<boolean> {
    return this.store$.pipe(select(fromStore.selectIsLoggedIn));
  }

  checkApiAuthentication() {
    return this.authService.authenticated;
  }
}
