import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { ErrorPage } from '@core/auth/components/error/error.page';
import { LoginPage } from '@core/auth/components/login/login.page';

@NgModule({
  imports: [CommonModule, HttpClientModule, RouterModule, FormsModule, IonicModule],
  declarations: [LoginPage, ErrorPage],
  exports: [LoginPage, ErrorPage]
})
export class CoreModule {}
