import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromStore from '@app-root/store';

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.page.html',
  styleUrls: ['./create-company.page.scss']
})
export class CreateCompanyPage implements OnInit, OnDestroy {
  companyCreationForm: FormGroup;
  customerCreated: boolean;
  displayError: boolean;
  errorMsg: string;
  didRequestFail: boolean;

  constructor(private fb: FormBuilder, private store$: Store<fromStore.State>) {}

  ngOnInit() {
    this.companyCreationForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.maxLength(500), this.validateEmptySpace]]
    });
    this.store$.pipe(select(fromStore.getDidRequestFail)).subscribe(failed => (this.didRequestFail = failed));
  }

  getErrorMessage() {
    const companyNameControl = this.companyCreationForm.get('companyName');
    if (this.doesContainErrors()) {
      return 'Please complete this required field.';
    }
    // Ensures we failed creation, and done with requests before showing error message
    // and to not show when there has been no attempt to create
    else if (this.didRequestFail && companyNameControl.pristine) {
      return 'Please try again. Demo company was not created.';
    } else {
      return '';
    }
  }

  doesContainErrors() {
    const companyNameControl = this.companyCreationForm.get('companyName');
    return (
      companyNameControl.invalid &&
      (companyNameControl.dirty || companyNameControl.touched) &&
      companyNameControl.errors
    );
  }

  handleCreateCompany() {
    if (this.companyCreationForm.get('companyName').touched) {
      this.store$.dispatch(fromStore.createCompany({ companyName: this.companyCreationForm.get('companyName').value }));
      // resets the form control
      this.companyCreationForm.get('companyName').markAsPristine();
    }
  }

  validateEmptySpace(control: FormControl) {
    const inputValue = control && control.value && control.value.toString();
    if (inputValue) {
      return !(inputValue.trim().length === 0) ? null : { containsWhiteSpace: true };
    }
  }

  ngOnDestroy(): void {}
}
