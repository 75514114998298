import { UserEffects } from './user.effects';
import { RouterEffects } from './router.effects';
import { AppEffects } from './app.effects';
import { AuthEffects } from './auth.effects';
import { PagesEffects } from './pages.effects';

export const effects: any[] = [RouterEffects, AppEffects, UserEffects, AuthEffects, PagesEffects];

export * from './user.effects';
export * from './router.effects';
export * from './app.effects';
export * from './auth.effects';
export * from './pages.effects';
