import { Company } from '@cargo-signal/shared';
import { createAction, props } from '@ngrx/store';

export const createCompany = createAction('[Pages] Create Company', props<{ companyName: string }>());
export const createCompanySuccess = createAction('[Pages] Create Company Success', props<{ company: Company }>());
export const createCompanyFail = createAction('[Pages] Create Company Fail');

export const createDemoShipments = createAction('[Pages] Create Demo Shipments', props<{ company: Company }>());

export const resetCreateDemoShipmentsFlag = createAction('[Pages] Reset Create Demo Shipments Flag');
export const createDemoShipmentsSuccess = createAction('[Pages] Create Demo Shipments Success');
export const createDemoShipmentsFail = createAction('[Pages] Create Demo Shipments Fail');
