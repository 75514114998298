import { createSelector } from '@ngrx/store';

import * as fromRoot from '../reducers/index';
import * as fromPages from '../reducers/pages.reducer';

export function getPagesState(state: fromRoot.State) {
  return state.pageReducer;
}

export const getCompanyName = createSelector(getPagesState, fromPages.getCompanyName);
export const getCompany = createSelector(getPagesState, fromPages.getCompany);
export const getDidRequestFail = createSelector(getPagesState, fromPages.getDidRequestFail);
