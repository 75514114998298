import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { ErrorPage } from '@core/auth/components/error/error.page';
import { UserGuard } from '@core/auth/guards/user.guard';
import { UserScopeGuard } from '@core/auth/guards/user-scope.guard';
import { LoginPage } from '@core/auth/components/login/login.page';
import { CreateCompanyPage } from './pages/create-company/create-company.page';
import { CreateCompanySuccessComponent } from './pages/create-company-success/create-company-success.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, UserGuard],
    canActivateChild: [AuthGuard, UserGuard],
    children: [
      {
        path: '',
        redirectTo: 'create-company',
        pathMatch: 'full'
      },
      {
        path: 'create-company',
        canActivate: [UserScopeGuard],
        canActivateChild: [UserScopeGuard],
        component: CreateCompanyPage
      },
      {
        path: 'create-company-success',
        canActivate: [UserScopeGuard],
        canActivateChild: [UserScopeGuard],
        component: CreateCompanySuccessComponent
      }
    ]
  },
  {
    path: 'app',
    redirectTo: 'AppComponent'
  },
  {
    path: 'callback',
    redirectTo: 'create-company'
  },
  {
    path: 'login',
    component: LoginPage
  },
  {
    path: 'error',
    component: ErrorPage
  },
  {
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
