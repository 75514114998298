import * as fromUser from '../actions/user.actions';
import { CompanyPermissionGroupMapping, User } from '@cargo-signal/shared';
import { createReducer, on } from '@ngrx/store';

export interface UserState {
  loading: boolean;
  loaded: boolean;
  user?: User;
  activeCompany: CompanyPermissionGroupMapping;
}

/** The Initial User state */
export const initialState: UserState = {
  loaded: false,
  loading: false,
  user: null,
  activeCompany: null
};

/** Takes in the current state and an action and returns a new state object */
export const userReducer = createReducer(
  initialState,
  on(fromUser.getUser, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(fromUser.getUserSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    user: action.user,
    activeCompany: action.user.associatedCompanies.find(c => c.companyId === action.user.activeCompanyId)
  })),
  on(fromUser.setActiveCompany, (state, action) => ({
    ...state,
    loading: true,
    loaded: false
  })),
  on(fromUser.setActiveCompanySuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    activeCompany: action.company
  }))
);

export const getUser = (state: UserState) => state.user;
export const getLoading = (state: UserState) => state.loading;
export const getLoaded = (state: UserState) => state.loaded;
export const getActiveCompany = (state: UserState) => state.activeCompany;
