import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { FormattedDatePipe } from '@shared/pipes/formatted-date.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, HttpClientModule, IonicModule],
  declarations: [FormattedDatePipe],
  exports: [CommonModule, FormsModule, RouterModule, HttpClientModule, FormattedDatePipe]
})
export class SharedModule {}
