import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '@cargo-signal/shared';
import { from, of } from 'rxjs';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { environment } from '@env';
import * as authActions from '../actions/auth.actions';
import * as routerActions from '../actions/router.actions';
import * as userActions from '../actions/user.actions';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions, private store$: Store<any>, private authService: AuthService) {}

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.login),
        exhaustMap(action => {
          return from(this.authService.getClient()).pipe(
            map(client => {
              const loginOptions: RedirectLoginOptions = {
                audience: environment.audience,
                display: 'touch',
                prompt: 'login',
                scope: environment.authScope,
                login: 'password',
                appState: { login: 'password' }
              };
              return { client, loginOptions };
            }),
            exhaustMap(({ client, loginOptions }) => {
              return [client.loginWithRedirect(loginOptions)];
            })
          );
        })
      ),
    { dispatch: false }
  );

  handleCallback$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.handleCallback),
      exhaustMap(action => {
        return from(this.authService.getClient()).pipe(
          exhaustMap(client => client.handleRedirectCallback(action.url)),
          exhaustMap(callbackResolve => {
            return this.authService.refresh(true).pipe(
              exhaustMap(token => {
                this.authService.token = token;
                return [authActions.loginSuccess(), routerActions.go({ path: [''] })];
              })
            );
          })
        );
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.loginSuccess),
      switchMap(() => {
        return [userActions.getUser()];
      })
    );
  });

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logout),
        switchMap(() => this.authService.logout().pipe(catchError(err => of(console.log(err))))),
        tap(() => {
          localStorage.clear();
        })
      ),
    { dispatch: false }
  );

  unauthorized$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActions.unauthorized),
      map(() => {
        return routerActions.go({ path: ['error'] });
      })
    );
  });
}
