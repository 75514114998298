import * as fromPages from '../actions/pages.actions';
import { createReducer, on } from '@ngrx/store';
import { Company } from '@cargo-signal/shared';

export interface PageState {
  companyName: string;
  didRequestFail: boolean;
  company: Company;
  companyId: string;
}

export const initialState: PageState = {
  companyName: '',
  didRequestFail: false,
  company: null,
  companyId: ''
};

export const pageReducer = createReducer(
  initialState,
  on(fromPages.createCompany, (state, action) => ({
    ...state,
    companyName: action.companyName
  })),
  on(fromPages.createCompanyFail, (state, action) => ({
    ...state,
    didRequestFail: true
  })),
  on(fromPages.createDemoShipments, (state, action) => ({
    ...state,
    company: action.company,
    didRequestFail: false
  })),
  on(fromPages.resetCreateDemoShipmentsFlag, state => ({
    ...state,
    didRequestFail: false,
    companyName: '',
    company: null,
    companyId: ''
  }))
);
export const getCompanyName = (state: PageState) => state.companyName;
export const getCompany = (state: PageState) => state.company;
export const getDidRequestFail = (state: PageState) => state.didRequestFail;
