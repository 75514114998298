import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import * as userActions from '../actions/user.actions';
import * as routerActions from '../actions/router.actions';
import { AuthService, User } from '@cargo-signal/shared';
import { NotifierService } from '@core/services/notifier/notifier.service';
import { of } from 'rxjs';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    private authService: AuthService,
    private notifierService: NotifierService
  ) {}

  /**
   * Fetches the current logged in user
   * Dispatches GetUserSuccess action to update the store
   */
  getUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.getUser),
      exhaustMap(() => {
        return this.authService.getUser().pipe(
          map((user: User) => {
            // Sort user's associated companies by companyName alphabetically
            user.associatedCompanies.sort((a, b) =>
              a.companyName > b.companyName ? 1 : a.companyName < b.companyName ? -1 : 0
            );
            return userActions.getUserSuccess({ user });
          })
        );
      })
    );
  });

  /**
   * Sets the user's active company in authService
   * Dispatches success event + redirect to create-company
   */
  setUserActiveCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.setActiveCompany),
      map(action => action.company),
      switchMap(company => {
        return this.authService.setUserActiveCompany(company.companyId).pipe(
          switchMap(() => [
            userActions.setActiveCompanySuccess({ company }),
            routerActions.go({ path: ['create-company'] })
          ]),
          catchError(error => {
            return of(userActions.setActiveCompanyFail({ error }));
          })
        );
      })
    );
  });

  setUserActiveCompanyFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setActiveCompanyFail),
        tap(() => this.notifierService.showNotification('This action could not be completed. Please try again later.'))
      ),
    { dispatch: false }
  );
}
