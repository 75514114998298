import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env';
import { CsSharedModule } from '@cargo-signal/shared';
import { JwtModule } from '@auth0/angular-jwt';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './store/reducers';
import { effects } from './store/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '@app-root/store';
import { SharedModule } from '@shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateCompanyPage } from './pages/create-company/create-company.page';
import { CreateCompanySuccessComponent } from './pages/create-company-success/create-company-success.component';

export function jwtTokenGetter() {
  return localStorage.getItem('authToken');
}

@NgModule({
  declarations: [AppComponent, CreateCompanyPage, CreateCompanySuccessComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CsSharedModule.forRoot({
      clientID: environment.clientID,
      domain: environment.domain,
      redirect: environment.redirect_uri,
      scope: environment.authScope,
      audience: environment.audience,
      appUrlPrefix: environment.appUrlPrefix
    }),
    CoreModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        allowedDomains: environment.whitelistedDomains,
        skipWhenExpired: true
      }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer
    }),
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [StatusBar, SplashScreen, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
